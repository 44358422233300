//import { find, head, isArray } from "lodash";
import { find, head } from "lodash";
import ApiService from "@/core/services/api.service";

export const GetContact = (type, uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`contact/${type}/${uuid}`)
			.then(({ data }) => {
				const contact = SetContact(data);
				resolve(contact);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const GetCustomerList = (type, search) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.query(`contact/${type}/list`, { search })
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const GetContactPersons = (customerUuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`contact-person/${customerUuid}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const GetCustomer = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`contact/customer/${uuid}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const GetVendor = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`contact/vendor/${uuid}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const GetProperty = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`property/${uuid}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const GetContactProperty = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`property/${uuid}/contact`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const ValidateCompanyName = (url, params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.query(url, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const CreateCustomer = (params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post("contact/customer", params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const CreateOrUpdateCustomer = (params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post("contact/customer", params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const CreateOrUpdateCustomerProperty = (customerUuid, params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(`contact/${customerUuid}/address`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const CreateVendor = (params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post("contact/vendor", params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const CreateOrUpdateVendor = (params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post("contact/vendor", params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const UpdateContact = (uuid, params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(`contact/${uuid}`, { ...params, uuid })
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const UpdateContactStatus = (uuid, params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(`contact/${uuid}/status`, { ...params, uuid })
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const UpdateContactParticular = (uuid, params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(`contact/${uuid}/particular`, { ...params, uuid })
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const DeleteContact = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.delete(`contact/${uuid}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const SetContact = (data) => {
	const barcode = data.barcode;
	let defaultContact = find(data.persons, { default: true }) || new Object();
	if (!defaultContact) {
		defaultContact = head(data.persons) || new Object();
	}

	const contact = data.contact;

	/* const contact = new Object({
		id: data.contact.id || null,
		uuid: data.contact.uuid || null,
		salutation: data.contact.title || "mr",
		first_name: data.contact.first_name || null,
		last_name: data.contact.last_name || null,
		company_name: data.contact.company_name || null,
		display_name: data.contact.display_name || null,
		email: data.contact.email || null,
		work_phone: data.contact.phone || null,
		mobile: data.contact.mobileNumber || null,
		fax: data.contact.fax || null,
		image:data.contact.image || null,
		website: data.contact.website || null,
		remark: data.contact.remark || null,
		billing: {
			id: billing.id || null,
			uuid: billing.uuid || null,
			attention: billing.attention || null,
			country: billing.country || null,
			street_1: billing.addressLineOne || null,
			street_2: billing.addressLineTwo || null,
			city: billing.city || null,
			state: billing.state || null,
			postalcode: billing.postalCode || null,
			phone: billing.phoneNumber || null,
			fax: billing.faxNumber || null,
		},
		shipping: {
			id: shipping.id || null,
			uuid: shipping.uuid || null,
			attention: shipping.attention || null,
			country: shipping.country || null,
			street_1: shipping.addressLineOne || null,
			street_2: shipping.addressLineTwo || null,
			city: shipping.city || null,
			state: shipping.state || null,
			postalcode: shipping.postalCode || null,
			phone: shipping.phoneNumber || null,
			fax: shipping.faxNumber || null,
		},
	}); */

	/* const contact_person = [];
	if (isArray(data.contact_person) && data.contact_person.length) {
		for (let i = 0; i < data.contact_person.length; i++) {
			contact_person.push({
				id: data.contact_person[i].id || null,
				uuid: data.contact_person[i].uuid || null,
				salutation: data.contact_person[i].title || null,
				first_name: data.contact_person[i].firstName || null,
				last_name: data.contact_person[i].lastName || null,
				email: data.contact_person[i].userEmail || null,
				work_phone: data.contact_person[i].phoneNumber || null,
				full_name: data.contact_person[i].fullName || null,
				default: data.contact_person[i].default || false,
				mobile: data.contact_person[i].mobileNumber || null,
			});
		}
	} */

	const contact_person = data.contact_person;

	return new Object({ barcode, contact, contact_person });
};

export const CreateBillingAddress = (uuid, params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post(`contact/${uuid}/address/billing`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const CreateShippingAddress = (uuid, params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post(`contact/${uuid}/address/shipping`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const GetAddress = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`contact/${uuid}/address`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const CreateOrUpdateAddress = (uuid, params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(`contact/${uuid}/address`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const UpdateAddress = (uuid, params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(`contact/address/${uuid}`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const UpdateStatusAddress = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(`contact/address/${uuid}/status`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const DeleteAddress = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.delete(`contact/address/${uuid}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const CreateOrUpdateContactPerson = (uuid, params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(`contact/${uuid}/person`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const CreateContactPerson = (uuid, params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post(`contact/${uuid}/person`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const GetContactPerson = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`contact/${uuid}/person`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const UpdateContactPerson = (uuid, params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(`contact/person/${uuid}`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const MarkAsDefaultContactPerson = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(`contact/person/${uuid}/default`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const UpdateStatusContactPerson = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(`contact/person/${uuid}/status`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const DeleteContactPerson = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.delete(`contact/person/${uuid}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
